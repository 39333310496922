.App {
  text-align: left;
  max-width: 80vw;
  margin: 0 auto 2rem;
}

a {
  margin: 0 0.3rem;
}
label,
input,
textarea {
  display: block;
}
